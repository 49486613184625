.containersx{
    max-width: 700px;
    width: 100%;
    padding: 25px 30px;
    border-radius: 5px;
    background: transparent;
    /* box-shadow: 0 5px 10px rgba(0,0,0,0.15); */
  }
  .containersx .title{
    font-size: 25px;
    font-weight: 500;
    color: white;
    position: relative;
  }
  .containersx .title::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, #71b7e6, #9b59b6);
  }
  .content .forms .user-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2px 0 12px 0;
  }
  .contentz .forms .user-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2px 0 12px 0;
  }
  .forms .user-details .input-box{
    margin-bottom: 15px;
    width: calc(100% / 2 - 20px);
  }
  .forms .input-box span.details{
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .user-details .input-box input{
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid black;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    color: black;
  }
  .user-details .input-box input:focus,
  .user-details .input-box input:valid{
    border-color:black;
  }
  .forms .gender-details .gender-title{
    font-size: 20px;
    font-weight: 500;
   }
   .forms .category{
     display: flex;
     width: 80%;
     margin: 14px 0 ;
     justify-content: space-between;
   }
   .forms .category label{
     display: flex;
     align-items: center;
     cursor: pointer;
   }
   .forms .category label .dot{
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d9d9d9;
    border: 5px solid transparent;
    transition: all 0.3s ease;
  }
   #dot-1:checked ~ .category label .one,
   #dot-2:checked ~ .category label .two,
   #dot-3:checked ~ .category label .three{
     background: #9b59b6;
     border-color: #d9d9d9;
   }
   /* form input[type="radio"]{
     display: none;
   } */
   .forms .button{
     height: 45px;
     margin: 35px 0
   }
   .forms .button button{
     height: 100%;
     width: 100%;
     border-radius: 5px;
     border: none;
     color: #fff;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 1px;
     cursor: pointer;
     transition: all 0.3s ease;
     background: rgb(182, 19, 19);
   }
   .forms .button button:hover{
    /* transform: scale(0.99); */
    background: linear-gradient(-135deg, #71b7e6, #9b59b6);
    }
   @media(max-width: 584px){
   .containersx{
    max-width: 100%;
  }
  .forms .user-details .input-box{
      margin-bottom: 15px;
      width: 100%;
    }
    .forms .category{
      width: 100%;
    }
    .contentz .forms .user-details{
      max-height: 180px;
      overflow-y: scroll;
    }
    .user-details::-webkit-scrollbar{
      width: 5px;
    }
    .user-two::-webkit-scrollbar{
      width: 5px;
    }
    }
    @media(max-width: 459px){
    .containersx .content .category{
      flex-direction: column;
    }
    .containersx{
      margin-top: -50px;
    }
    .content{
    margin-left: -50px;
    margin-right: -50px;  
    }
    
  }
  .loader{
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 3px solid transparent;
    border-top-color: white;
    animation: an1 1s ease infinite;
  }

  @keyframes an1 {
    0% {
      transform: rotate(0turn);
    }
    100% {
      transform: rotate(1turn);
    }
  }

  body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    
    padding: 10px 20px;
    display: inline;
    margin: 10px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;

   
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}