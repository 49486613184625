.loginmain{
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}
.bal{
    border: 2px solid orange;
    border-radius: 8px;
    text-align: center;
    margin: 30px 1px;
}

.botam{
    width: 100%;
    padding: 5px 0;
    margin: 50px 1px;
}

.pdfbtn{
    border: 1px solid black;
    text-align: center;
    color: white;
    border-radius: 5px;
    padding: 8px 18px;
    background:#24a0ed;
}


body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    
    padding: 10px 20px;
    display: inline;
    margin: 10px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background-color: #f1f1f1 !important;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}
