@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Review (SwiperJs) */

.swiper {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  z-index: -1;
  /* Center slide text vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -4;
}

.primeSwiper .swiper-slide img {
border: 1px solid black;
}


.common-heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3rem;
  text-transform: capitalize;
  position: relative;
  padding-left: 3rem;
  color:black;
}

.common-heading::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 5;
  width: 1rem;
  height: 1rem;
  background: yellow;
  border-radius: 50%;
}

.common-heading::after {
  content: "";
  position: absolute;
  top: 114%;
  left: 3rem;
  min-width: 12rem;

  height: 0.2rem;
  background:yellow;
}

.section{
  padding: 1rem 0;
  z-index: -1;
}

.container{
  max-width: 140rem;
  margin: 0 auto;
}

@media (max-width: 95rem) {
  .container {
    max-width: 130rem;
    padding: 0 3.2rem;
  }
}

@media (min-width: 320px){
  .regulatory{
    font-size: 1rem !important;
  }
}
@media (min-width: 320px){
  .common-heading{
    font-size: 1.3rem;
  }
  .common-heading::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 5;
    width: .8rem;
    height: .8rem;
    background: rgb(81, 81, 1);
    border-radius: 50%;
  }
  
  .common-heading::after {
    content: "";
    position: absolute;
    top: 115%;
    left: 3rem;
    min-width: 8rem;
  
    height: 0.18rem;
    background: rgb(81, 81, 1);
  }
}
@media (min-width: 768px){
  .common-heading{
    font-size: 2rem;
  }
  .common-heading::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 5;
    width: 1rem;
    height: 1rem;
    background: rgb(81, 81, 1);
    border-radius: 50%;
  }
  
  .common-heading::after {
    content: "";
    position: absolute;
    top: 114%;
    left: 3rem;
    min-width: 12rem;
  
    height: 0.2rem;
    background: rgb(81, 81, 1);
  }
  .regulatory{
    font-size: 2rem !important;
  }
}


/* JS Counter */

.wrapper4 {
  position: absolute;
  width: 80vw;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  z-index: -2;
}
.container4 {
  width: 28vmin;
  height: 28vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  position: relative;
  font-size: 16px;
  border-radius: 0.5em;
  background-color: #21242b;
  border-bottom: 10px solid #18f98f;
}
i {
  color: #18f98f;
  font-size: 2.5em;
  text-align: center;
}
span.num {
  color: #ffffff;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}
span.text {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  pad: 0.7em 0;
  font-weight: 400;
  line-height: 0;
}
@media screen and (max-width: 1024px) {
  .wrapper4 {
    width: 85vw;
  }
  .container4 {
    height: 26vmin;
    width: 26vmin;
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper4 {
    width: 90vw;
    flex-wrap: wrap;
    gap: 30px;
  }
  .container4 {
    width: calc(50% - 40px);
    height: 30vmin;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper4 {
    gap: 15px;
  }
  .container4 {
    width: 100%;
    height: 25vmin;
    font-size: 8px;
  }
}


.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}


.homeregbutton{
  border: 1px solid black;
  border-radius: 5px;
  padding: 12px 30px;

}



