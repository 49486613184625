.section {
  padding: 2rem 0;
}
.container {
  max-width: 140rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 3rem;
}

.grid-four-column {
  grid-template-columns: repeat(4, 1fr);

}
.section-work-data {
  /* background: var(--gradientSupport); */
  text-align: center;
  transition: all 0.7s linear;
  color: black;
}

.counter-numbers {
  font-size: 4.8rem;
}

.section-work-data p {
  color: var(--white);
  text-transform: capitalize;
}